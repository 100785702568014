import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [color, setColor] = useState(null);
  const [quantities, setQuantities] = useState(1);
  const [selectedColor, setSelectedColor] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { addToCart } = useContext(CartContext);

  const selectColor = (color) => {
    setColor(color);
    setSelectedColor(color);
  };

  const handleAddToCart = () => {
    if (!color) {
      alert("Please select a size before adding to cart.");
      return;
    }

    addToCart(product.id, quantities, color);
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
            <div className="goBackToShop">
              <Link to="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</Link>
            </div>
          </div>
          <div className="product-info">
            <div className="rightTitle">
              <h1>{product.name}</h1>
              <dd>Trendco</dd>
            </div>

            <div className="priceDiv">
              <p className="product-price">{product.price}</p>
              <p className="product-prePrice">{product.preprice}</p>
              <span className="priceSpan">
                <p className="priceRight">Exclusive Online Price</p>
              </span>
            </div>
            <p className="product-description">
              Tax included. You may be able to get this VAT-Free if your
              hair-loss is medical related. See VAT Free below.
            </p>

            <div className="template_content">
              <div className="product-sizes">
                <h3>Color: {color}</h3>
                <ul>
                  {product.color.map((color, index) => (
                    <li
                      key={index}
                      onClick={() => selectColor(color)}
                      className={selectedColor === color ? "selected" : ""}
                    >
                      {color}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="product-quantity">
                <h3>Quantity</h3>
                <input
                  type="number"
                  value={quantities}
                  min="1" // 设置最小值为1
                  onChange={(e) => {
                    // 确保输入是数字，并且不小于1
                    const newQuantity = parseInt(e.target.value);
                    setQuantities(
                      isNaN(newQuantity) || newQuantity < 1 ? 1 : newQuantity
                    );
                  }}
                  className="quantity-input"
                />
              </div>
              <div className="btnDiv">
                <button className="add-to-cart" onClick={handleAddToCart}>
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
