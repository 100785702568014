import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFolder } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <>
      <span className="threeTitle">
        <h3>Exclusive updates & offers</h3>
      </span>
      <div className="bottom-container">
        <div className="footLeftImg">
          <img alt="" src="/footImg/foot1.svg"></img>
        </div>
        <div className="botLeft">
          <ul>
            <li>
              <Link to="/contact">
                <FontAwesomeIcon icon={faEnvelope} />
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/privacy">
                <FontAwesomeIcon icon={faFolder} /> Privacy & Cookies
              </Link>
            </li>
            <li>
              <Link to="/aboutUs">
                <FontAwesomeIcon icon={faFolder} /> Help & Support for Hair-loss
              </Link>
            </li>
          </ul>
          <div className="botLRight">
            <div className="footerRow1">
              <spn className="footSpan">
                Shopping with us is safe and secure
              </spn>
            </div>
            <Link to="/">
              <img alt="" src="/faceIcon/icon4.png" />
            </Link>
            <Link to="/">
              <img alt="" src="/faceIcon/icon2.png" />
            </Link>
            <Link to="/">
              <img alt="" src="/faceIcon/icon3.png" />
            </Link>
          </div>
        </div>
      </div>
      <div className="legal">
        <p className="legalLeft">
          © 2024 The Wonderful Wig Company
          <br />
          <img
            alt=""
            src="https://images.squarespace-cdn.com/content/v1/654368c2f026c001e46e5e25/1698916555122-Y2I6OYQXKNK28YD5XEI0/logo-light.png"
          ></img>
        </p>
        <p className="legalRight">
          <div className="faceIcons">
            <Link to="https://www.facebook.com/petplanetuk">
              <img src="/faceIcon/face.png" alt="Facebook" />
            </Link>
            <Link to="https://www.instagram.com/petplanet.co.uk">
              <img src="/faceIcon/insta.png" alt="Instagram" />
            </Link>
            <Link to="https://twitter.com/PetPlanetcouk">
              <img src="/faceIcon/Twir.png" alt="Twitter" />
            </Link>
            <Link to="https://www.youtube.com/PetPlanetcouk">
              <img src="/faceIcon/Ytub.png" alt="YouTube" />
            </Link>
          </div>
        </p>
      </div>
    </>
  );
}

export default Footer;
