import "../css/header.css";
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const { cartItems } = useContext(CartContext);

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="leftUU">
            <ul className="leftUl">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/aboutUs">About Us</Link>
              </li>
            </ul>
          </div>
          <div className="center">
            <Link to="/">THE UK'S NO.1 BEST-RATED WIG STORE!</Link>
          </div>
        </div>
        <div className="headerMid">
          <div className="headImg">
            <Link to="/">
              <img alt="" src="/hairsImg/header1.png" />
            </Link>
          </div>
          <div className="headSvg">
            <Link to="/cartpage">
              <FontAwesomeIcon icon={faBagShopping} />
              <span className="cart-count">{cartItems.length}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
