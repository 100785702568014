import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";


function ContactUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="h2cc">
        <div className="contactH2">
          <h2>CONTACT US</h2>
        </div>
      </div>
      <div className="contactDiv">
        <div className="innerDiv">
          <div className="contactForm">
            <div className="contact-content1">
              <h2>Got a question?</h2>
              <p className="contact-content1-p1">
                If you have any questions, our wonderful team of hair loss
                experts and stylists are here to help. If your enquiry isn’t
                urgent, the best way to get in-touch is using the form below. If
                you have an urgent query, just give us a call on{" "}
                <strong>0191 232 9432.</strong>
              </p>
              <p className="aLInk">
                <a className="link">
                  Questions about ordering? You might find our FAQs useful
                </a>
              </p>
              <p className="aLInk">
                <a className="link">Delivery & Returns information</a>
              </p>
              <p className="aLInk">
                <a className="link">
                  I need to speak to someone via phone or email
                </a>
              </p>
            </div>
            <div className="inputTxt">
              <h1>Send us a message</h1>
              <div className="contact-content">
                <strong className="inputLable">Name</strong>
                <input type="text" />
              </div>
              <div className="contact-content">
                <strong className="inputLable">Email*</strong>
                <input type="text" required />
              </div>
              <div className="contact-content">
                <strong className="inputLable">Phone Number</strong>
                <input type="text" />
              </div>

              <div className="contact-content">
                <strong className="inputLable">Message</strong>
                <textarea type="text" />
              </div>
              <div className="btnDiv">
                <button>Send</button>
              </div>
            </div>

            <div className="link-content">
              <h1>Our salons</h1>
              <p>
                Please note that appointments at both our salons in Newcastle
                and Sundelrand are by appointment only.
              </p>
              <div className="sunDiv">
                <div className="sunDivLeft">
                  <h1>Sunderland</h1>
                  <p>Monday-Tuesday: 9am - 6pm</p>
                  <p>Wednesday: 9:30am - 8pm</p>
                  <p>Thursday-Friday: 9am - 6pm</p>
                  <p>Saturday-Sunday: Closed</p>
                  <span>
                    34 Melbourne Place,
                    <br />
                    Sunderland, SR4 8LN
                  </span>
                </div>
                <div>
                  <h1>Newcastle (Ouseburn)</h1>
                  <span>
                    28 Lime Street,
                    <br />
                    Newcastle Upon Tyne,
                    <br />
                    NE1 2PL
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
